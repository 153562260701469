/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportRailiumTasksByIdRequest
 */
export interface ImportRailiumTasksByIdRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ImportRailiumTasksByIdRequest
     */
    taskIds: Array<number>;
    /**
     * Im Berichtsmanagement werden nur zukünftige Railium-Einsätze aktualisiert. Wenn ignoreDate wahr ist, werden die angegebenen Einsätze aktualisiert,
     *                 auch wenn diese in der Vergangenheit liegen.
     * 
     * @type {boolean}
     * @memberof ImportRailiumTasksByIdRequest
     */
    ignoreDate: boolean;
}

/**
 * Check if a given object implements the ImportRailiumTasksByIdRequest interface.
 */
export function instanceOfImportRailiumTasksByIdRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "taskIds" in value;
    isInstance = isInstance && "ignoreDate" in value;

    return isInstance;
}

export function ImportRailiumTasksByIdRequestFromJSON(json: any): ImportRailiumTasksByIdRequest {
    return ImportRailiumTasksByIdRequestFromJSONTyped(json, false);
}

export function ImportRailiumTasksByIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportRailiumTasksByIdRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskIds': json['taskIds'],
        'ignoreDate': json['ignoreDate'],
    };
}

export function ImportRailiumTasksByIdRequestToJSON(value?: ImportRailiumTasksByIdRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'taskIds': value.taskIds,
        'ignoreDate': value.ignoreDate,
    };
}

